const React = require('react');
const { arrayOf, bool, object } = require('prop-types');

const Image = require('../../../commons/image');

function ItemsSlide({ items, lazyLoad }) {
  const imgSize = '70';
  return (
    <div className="collections-slide-order-wrapper">
      <div className="collections-slide-items">
        {items.map((item) => (
          <a
            key={item.id}
            className="collections-slide-item"
            href={item.permalink}
          >
            <Image
              size={imgSize}
              src={item.picture.src}
              lazyLoad={lazyLoad}
              alt={item.title}
            />
          </a>
        ))}
      </div>
    </div>
  );
}
ItemsSlide.propTypes = {
  items: arrayOf(object),
  lazyLoad: bool.isRequired,
};

ItemsSlide.defaultProps = {
  items: [],
};

module.exports = ItemsSlide;
