const React = require('react');
const { useState } = require('react');
const { shape, bool, string, node, arrayOf } = require('prop-types');

const classNames = require('classnames');
const withTracker = require('../../../commons/with-tracker');
const Image = require('../../../commons/image');
const ItemsSlide = require('./items-slide');


function CollectionsSlide({
  title,
  target_url,
  items,
  lazyLoad,
  background_picture,
}) {
  // eslint-disable-next-line no-unused-vars
  const [itemsSlide, setItemsSlide] = useState(items.slice(0, 4));
  return (
    <div
      className={classNames('ui-card', 'collections-slide')}
    >
      <h2 className="collections-slide_title-wrapper">
        <a alt={title} href={target_url}>
          <p className="collections-slide-title">{title}</p>
        </a>
      </h2>
      <div className="collections-slide-background">
        <Image {...background_picture} lazyLoad={lazyLoad} alt={title} />
      </div>
      <ItemsSlide items={itemsSlide} lazyLoad={lazyLoad} />
    </div>
  );
}

CollectionsSlide.propTypes = {
  background_picture: shape(Image.propTypes),
  lazyLoad: bool.isRequired,
  logo_picture: shape(Image.propTypes),
  lowEnd: bool.isRequired,
  target_url: string.isRequired,
  title: string.isRequired,
  children: node,
  items: arrayOf(shape({})),
};

CollectionsSlide.defaultProps = {
  background_picture: {
    src: '',
    src2x: '',
  },
  children: null,
};

module.exports = withTracker(CollectionsSlide);
